















import { Prop, Component, Vue } from 'vue-property-decorator';
import LogoSVG from '@/assets/img/logo.svg';

@Component({
  components: { LogoSVG }
})
export default class NoPlace extends Vue {
  @Prop({}) data!: any;
}
